<template>
    <v-dialog
        v-model="isUserBuyerModalShown"
        persistent
        width="500"
    >
        <v-card>
            <v-card-title>
                Welcome to the auction admin panel
            </v-card-title>
            <v-card-text>
                <p>
                    You are registered as a buyer, so you do not have access to use this system module.
                    To increase access rights, register as an agent or contact an administrator.
                </p>
                <v-btn
                    dark
                    @click.stop="logOut"
                >
                    Logout
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'UserBuyerModal',

    data: () => ({
        isUserBuyerModalOpen: false,
    }),

    computed: {
        ...mapState(['isUserBuyerModalShown']),
    },
    watch: {
        isUserBuyerModalOpen() {
            if (!this.isUserBuyerModalOpen) {
                this.$store.dispatch('closeUserBuyerModal');
            }
        },
        isUserBuyerModalShown() {
            if (this.isUserBuyerModalShown) {
                this.isUserBuyerModalOpen = true;
            }
        },
    },

    mounted() {
        this.isUserBuyerModalOpen = this.isUserBuyerModalShown;
    },

    methods: {
        logOut() {
            this.$store.dispatch('logOut');
            this.$store.dispatch('closeUserBuyerModal');
        },
    },
};
</script>