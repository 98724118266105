<template>
    <div
        ref="overlay"
        class="overlay"
        @click="onOverlayClick($event)"
    >
        <v-card class="modal-card">
            <div
                v-show="loading"
                class="spinner-wrapper"
            >
                <v-progress-circular
                    size="48"
                    indeterminate
                />
            </div>
            <iframe
                v-show="!loading"
                ref="iframe"
                :src="`${accountsAppUrl}/#/personal-info${tab}`"
                frameborder="0"
            />
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            accountsAppUrl: process.env.VUE_APP_ACCOUNTS_URL,
        };
    },
    computed: {
        tab() {
            const tab = this.$store.state.personalInfoModal.tab;
            return this.$store.state.personalInfoModal.tab ? '?tab=' + tab : '';
        },
    },
    mounted() {
        this.$refs.iframe.addEventListener('load', this.hideSpinner);
    },
    beforeDestroy() {
        this.$refs.iframe.removeEventListener('load', this.hideSpinner);
    },
    methods: {
        onOverlayClick(e) {
            if (e.target === this.$refs.overlay) {
                this.$store.dispatch('closePersonalInfo');
            }
        },
        hideSpinner() {
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-card {
    width: 1000px;
    max-width: 100%;
    height: 600px;
    max-height: 100%;
    overflow: hidden;
}

.spinner-wrapper {
    width: 100%;
    height: 100%;
}

iframe {
    width: calc(100% + 18px);
    height: 100%;
}

.card-header {
    padding: 8px 25px;
    border-bottom: 2px solid #c3c3c3;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>