import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

export const Asset = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
