import { v4 as uuidv4 } from 'uuid';

export const Utils = {
    copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },

    /*
     * Example key
     * protected/user-identity-id/image.jpg
     */
    parseFileKey(key) {
        return {
            key: key.split('/')[2],
            identityId: key.split('/')[1],
            level: key.split('/')[0],
        };
    },

    extractFileKey(str) {
        return str.includes('/') ? str.split('/').pop() : str;
    },

    /*
     * Example
     * fileType = image/png
     */
    generateFileKey(fileType) {
        return uuidv4() + '.' + fileType.split('/').pop();
    },

    extractKeysFromForm(form) {
        return form
            .map((section) => {
                return section.map((row) => {
                    return row.map((col) => col.key);
                });
            })
            .flat(2);
    },

    filterObject(form, keys) {
        return Object.keys(form)
            .filter((key) => keys.includes(key))
            .reduce((obj, key) => {
                obj[key] = form[key];
                return obj;
            }, {});
    },

    formatPrice(price) {
        if (!price) return;
        return new Intl.NumberFormat('en-US').format(price);
    },

    formatDate(date) {
        return Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).format(new Date(date));
    },

    getFileIcon(type) {
        if (type.includes('pdf')) {
            return 'mdi-file-pdf';
        } else if (['image', 'jpeg', 'jpg', 'png', 'webp'].some((substr) => type.indexOf(substr) >= 0)) {
            return 'mdi-file-image-outline';
        } else {
            return 'mdi-file';
        }
    },

    formatPhoneNumber(value) {
        const newValueArr = value.replace(/[^\d]/g, '').split('');

        const firstPart = newValueArr.slice(0, 3).join('');
        const secondPart = newValueArr.slice(3, 6).join('');
        const thirdPart = newValueArr.slice(6, 10).join('');

        let newValue = '';
        newValue += firstPart ? `(${firstPart})` : '';
        newValue += secondPart ? ` ${secondPart}` : '';
        newValue += thirdPart ? `-${thirdPart}` : '';

        return newValue;
    },
};
