import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('./pages/AdminDashboard'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: '/assets',
                },
                {
                    path: 'assets',
                    name: 'assets',
                    component: () => import('./components/AdminDashboard/AdminDashboardListing/AdminDashboardListings'),
                    meta: {
                        title: 'Assets',
                    },
                },
                {
                    path: 'assets/new',
                    name: 'new-asset',
                    component: () => import('./components/Asset/AssetForm'),
                    meta: {
                        title: 'New Asset',
                    },
                },
                {
                    path: 'assets/short-info/:id/:kind',
                    component: () => import('./components/Asset/AssetShortInfo/AssetShortInfo'),
                    meta: {
                        title: 'Edit Asset',
                    },
                },
                {
                    path: 'assets/publication/:id/:kind',
                    name: 'create-sale',
                    component: () => import('./components/Asset/AssetPublication'),
                    meta: {
                        title: 'Create sale',
                    },
                },
                {
                    path: 'assets/publication/edit/:id/:kind',
                    name: 'edit-sale',
                    component: () => import('./components/Asset/AssetPublication'),
                    meta: {
                        title: 'Edit sale',
                    },
                },
                {
                    path: 'assets/:id/:kind',
                    component: () => import('./components/Asset/AssetForm'),
                    meta: {
                        title: 'Edit Asset',
                    },
                },
            ],
        },
        {
            path: '/auth/login',
            component: () => import('./pages/Login.vue'),
            meta: {
                title: 'Login',
            },
        },
        {
            path: '*',
            redirect: '/assets',
        },
    ],
});

router.beforeEach((to, from, next) => {
    const loggedIn = !!localStorage.getItem('authenticated');
    document.title = to.meta.title ? `${to.meta.title + ' - '}Management Dashboard` : 'Management Dashboard';

    if (to.matched.some((path) => path.meta.requiresAuth) && !loggedIn) {
        next('/auth/login');
    } else {
        next();
    }
});

export default router;
