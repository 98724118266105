import { getDefaultState } from '@/store/Asset/state';

export const mutations = {
    setAssetKind(state, kind) {
        state.kind = kind;
    },

    setFeatures(state, payload) {
        state.data.features = {
            ...payload,
        };
    },

    setInterior(state, payload) {
        state.data.interior = {
            ...payload,
        };
    },

    setExterior(state, payload) {
        state.data.exterior = {
            ...payload,
        };
    },

    setDocuments(state, payload) {
        state.files.documents = [...payload];
    },

    setMediaFiles(state, payload) {
        state.files.images = payload;
    },

    setMediaKeys(state, payload) {
        state.images = {
            ...payload,
        };
    },

    setSavingState(state, payload = { isSaving: false, total: 0 }) {
        state.savingState = {
            ...state.savingState,
            ...payload,
        };
    },

    setGeneralInfo(state, payload) {
        const { address, city, state: assetState, zip, openHouseDates, description, title } = payload;

        state.address = {
            address,
            state: assetState,
            city,
            zip,
        };

        state.title = title;
        state.openHouseDates = openHouseDates;
        state.description = description;
    },

    setOptions(state, payload) {
        const filtered = Object.keys(payload)
            .filter((key) => !!payload[key])
            .reduce((obj, key) => {
                obj[key] = payload[key];
                return obj;
            }, {});

        filtered.boolean = [
            {
                text: 'Yes',
                value: true,
            },
            {
                text: 'No',
                value: false,
            },
        ];

        state.options[state.kind] = filtered;
    },

    resetState(state) {
        const options = state.options;

        Object.assign(state, getDefaultState());

        state.options = options;
    },
};
