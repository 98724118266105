import { API } from '@aws-amplify/api';

const ASSET_GENERAL = {
    Basic: `
        title
        description
        kind
        images {
            mainImage
            firstRaw {
                type
                url
                thumbnail
            }
            secondRaw {
                type
                url
                thumbnail
            }
            hidden {
                type
                url
                thumbnail
            }
        }
        documents {
            key
            name
        }`,
    Residential: `
        address {
            address
            city
            state
            zip
        }
        openHouseDates
        description
        kind
        images {
            mainImage
            firstRaw {
                type
                url
                thumbnail
            }
            secondRaw {
                type
                url
                thumbnail
            }
            hidden {
                type
                url
                thumbnail
            }
        }
        documents {
            key
            name
        }`,
    Land: `
        address {
            address
            city
            state
            zip
        }
        openHouseDates
        description
        kind
        images {
            mainImage
            firstRaw {
                type
                url
                thumbnail
            }
            secondRaw {
                type
                url
                thumbnail
            }
            hidden {
                type
                url
                thumbnail
            }
        }
        documents {
            key
            name
        }`,
    Commercial: `
        address {
            address
            city
            state
            zip
        }
        openHouseDates
        description
        kind
        images {
            mainImage
            firstRaw {
                type
                url
                thumbnail
            }
            secondRaw {
                type
                url
                thumbnail
            }
            hidden {
                type
                url
                thumbnail
            }
        }
        documents {
            key
            name
        }`,
};

const ASSET_MAIN_FEATURES = {
    Basic: `
        title
    `,
    Residential: `data {
        ... on Residential {
            features {
                beds
                squareFootage
                baths
                yearBuilt
            }
        }
    }`,
    Land: `data {
        ... on Land {
            features {
                totalAcres
            }
        }
    }`,
    Commercial: `data {
        ... on Commercial {
            features {
                squareFootage
                yearBuilt
            }
        }
    }`,
};

const ASSET_BODY = {
    Basic: `
        title
        description
    `,
    Residential: `data {
        ... on Residential {
            features {
                propertyType
                propertySubType
                beds
                baths
                squareFootage
                yearBuilt
                yearRenovated
                lot
                lotUnit
                lotDimentions
                brokerCoOp
                termsAccepted
                occupiedBy
                ownership
                possession
                homeWarranty
                style
                stories
                garageSpaces
                basement
                recentUpdates
                securityFeatures
                cooling
                heating
                electric
                gas
                water
                sewer
                county
                zoning
                subdivision
                schoolDistrict
                propertyTaxes
                specialAssessmentTaxes
                taxExemptions
                hoaFee
                hoaUnit
                hoaAmenities
            }
            interior {
                sqftMainFloor
                sqftUpperFloor
                sqftBasement
                bedroomsMainFloor
                bedroomsUpperFloor
                bedroomsBasement
                bathroomsMainFloor
                bathroomsUpperFloor
                bathroomsBasement
                kitchenFeatures
                appliances
                flooring
                windows
                bedroomFeatures
                bathroomFeatures
                masterBedroomFeatures
                basementFeatures
                otherRooms
                otherFeatures
                fireplace
                fireplaceUnit
                handicapAmenities
            }
            exterior {
                construction
                exteriorFeatures
                roof
                foundation
                fence
                pool
                garageParking
                garageFeatures
                outbuildings
                locationFeatures
                roadFrontage
                propertyFaces
            }
        }
    }`,
    Commercial: `data {
        ... on Commercial {
            features {
                propertyType
                propertySubType
                squareFootage
                yearBuilt
                yearRenovated
                lot
                lotUnit
                lotDimentions
                brokerCoOp
                termsAccepted
                leaseType
                leaseExpiration
                occupiedBy
                ownership
                possession
                totalBuildings
                totalUnits
                totalFloors
                garageSpaces
                basement
                recentUpdates
                securityFeatures
                netOperatingIncome
                occupancy
                capRate
                averageMonthlyRentUnit
                tenantPays
                inclusions
                buildingClass
                cooling
                heating
                electric
                gas
                water
                sewer
                county
                zoning
                subdivision
                propertyTaxes
                specialAssessmentTaxes
                taxExemptions
                hoaFee
                hoaUnit
                hoaAmenities
            }
            interior {
                totalRooms
                totalBedrooms
                totalBathrooms
                totalPublicRestrooms
                ceilingHeight
                appliances
                flooring
                windows
                interiorFeatures
                basementFeatures
                handicapAmenities
            }
            exterior {
                construction
                exteriorFeatures
                roof
                foundation
                fence
                pool
                garageParking
                outbuildings
                locationFeatures
                roadFrontage
                propertyFaces
            }
        }
    }`,
    Land: `data {
        ... on Land {
            features {
                propertyType
                propertySubType
                totalAcres
                drylandAcres
                irrigatedAcres
                grassAcres
                pastureFencedAcres
                crpAcres
                timberAcres
                lotAcres
                balanceOtherAcres
                brokerCoOp
                termsAccepted
                ownership
                possession
                leaseExpiration
                mineralRights
                easements
                survey
                water
                utilities
                sewer
                ponds
                wells
                fsaInformation
                cropYieldHistory
                soilProductivityRating
                improvements
                outbuildings
                otherFeatures
                fence
                irrigationSystem
                livestockCarryingCapacity
                annualPayment
                expires
                roadFrontage
                topography
                wildlife
                fish
                recreation
                county
                zoning
                subdivision
                propertyTaxes
                specialAssessmentTaxes
                taxExemptions
            }
        }
    }`,
};

export default {
    createAsset(asset) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `mutation create${asset.kind}Asset($asset: ${asset.kind}AssetInput!) {
            create${asset.kind}Asset(asset: $asset) {
                id
                kind
                ${ASSET_BODY[asset.kind]}
            }
        }`;

        return API.graphql({
            query,
            variables: {
                asset,
            },
        });
    },

    updateAsset(id, asset) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `mutation edit${asset.kind}Asset($id:String!, $asset:${asset.kind}AssetInput!) {
            edit${asset.kind}Asset(id: $id, asset: $asset) {
                id
                ${ASSET_GENERAL[asset.kind]}
                ${ASSET_BODY[asset.kind]}
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
                asset,
            },
        });
    },

    getAssetById(id, kind) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `query get${kind}Asset($id: String!) {
            get${kind}Asset(id: $id) {
                status
                ${ASSET_GENERAL[kind]}
                ${ASSET_BODY[kind]}
                sale {
                    status
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    switchStatus(id, status) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `mutation switchStatus($id:String!, $status: AssetStatusSwitch!) {
            switchStatus(id: $id, status: $status) {
                ... on BasicAsset {
                    id
                    title
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
                ... on ResidentialAsset {
                    id
                    address {
                        address
                        city
                        state
                        zip
                    }
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
                ... on CommercialAsset {
                    id
                    address {
                        address
                        city
                        state
                        zip
                    }
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
                ... on LandAsset {
                    id
                    address {
                        address
                        city
                        state
                        zip
                    }
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
                status,
            },
        });
    },

    getAssetRecommended(kind) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `query getAssetRecommended($kind: AssetKind!) {
            getAssetRecommended(kind: $kind) {
                propertyType
                propertySubType
                brokerCoOp
                termsAccepted
                ownership
                possession
                water
                sewer
                zoning
                taxExemptions
                outbuildings
                fence
                roadFrontage
                lotUnit
                occupiedBy
                basement
                recentUpdates
                securityFeatures
                cooling
                heating
                electric
                gas
                hoaUnit
                hoaAmenities
                appliances
                flooring
                windows
                basementFeatures
                handicapAmenities
                construction
                exteriorFeatures
                roof
                foundation
                pool
                garageParking
                locationFeatures
                propertyFaces
                otherFeatures
                homeWarranty
                style
                stories
                kitchenFeatures
                bedroomFeatures
                bathroomFeatures
                masterBedroomFeatures
                otherRooms
                fireplaceUnit
                garageFeatures
                leaseType
                tenantPays
                inclusions
                buildingClass
                interiorFeatures
                mineralRights
                easements
                survey
                utilities
                improvements
                irrigationSystem
                topography
                wildlife
                fish
                recreation
            }
        }`;

        return API.graphql({
            query,
            variables: {
                kind,
            },
        });
    },

    getAssetShortInfo(id, kind) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `query get${kind}Asset($id: String!) {
            get${kind}Asset(id: $id) {
                id
                description
                status
                address {
                    address
                    city
                    state
                    zip
                }
                kind
                images {
                    mainImage
                }
                ${ASSET_MAIN_FEATURES[kind]}
                sale {
                    id
                    createdAt
                    status
                    kind
                    listingSale {
                        listingPrice
                    }
                    englishAuction {
                        startingPrice
                        bidIncrement
                        startDate
                        endDate
                        backups
                        currentBidValue
                        details
                        pendingParticipations
                        approvedParticipations
                    }
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    createListingSale(sale) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;
        const query = `mutation createListingSale($sale: ListingSaleInput!) {
            createListingSale(sale: $sale) {
                id
                assetId
                kind
                listingSale {
                    listingPrice
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                sale,
            },
        });
    },

    createEnglishAuction(auction) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;
        const query = `mutation createEnglishAuction($auction: AuctionInput!) {
            createEnglishAuction(auction: $auction) {
                kind
                englishAuction {
                    details
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                auction,
            },
        });
    },

    editAuction(auction) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;
        const query = `mutation editAuction($auction: EditAuction!) {
            editAuction(auction: $auction) {
                id
                createdAt
                assetId
                agentId
                status
                kind
                listingSale {
                    listingPrice
                }
                englishAuction {
                    startingPrice
                    bidIncrement
                    startDate
                    endDate
                    backups
                    currentBidValue
                    currentBidDate
                    currentBidID
                    currentBidOwner
                    details
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                auction,
            },
        });
    },

    parseVideoEmbed(embed) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `query parseVideoEmbed($embed: String!) {
            parseVideoEmbed(embed: $embed) {
                type
                url
                thumbnail
            }
        }`;

        return API.graphql({
            query,
            variables: {
                embed,
            },
        });
    },

    switchListingStatus(id, status) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;
        const query = `mutation switchListingStatus($id: String!, $status: ListingStatusSwitch!) {
            switchListingStatus(id: $id, status: $status) {
                status
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
                status,
            },
        });
    },

    switchUserEnglishAuctionStatus(id, status) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;
        const query = `mutation switchUserEnglishAuctionStatus($id: String!, $status: AuctionStatusUser!) {
            switchUserEnglishAuctionStatus(id: $id, status: $status) {
                id
                status
                englishAuction {
                    backups
                    endDate
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
                status,
            },
        });
    },

    // listAssetParticipations(assetId, limit = 100, nextToken = null) {
    //     API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;
    //
    //     const query = `query listAssetParticipations($assetId: String!, $limit: Int, $nextToken: String) {
    //         listAssetParticipations(assetId: $assetId, limit: $limit, nextToken: $nextToken) {
    //             count
    //             data {
    //                 id
    //                 bid {
    //                     bidValue
    //                     status
    //                     createdAt
    //                 }
    //                 personalInfo {
    //                     firstName
    //                     lastName
    //                 }
    //             }
    //         }
    //     }`;
    //
    //     return API.graphql({
    //         query,
    //         variables: {
    //             assetId,
    //             limit,
    //             nextToken,
    //         },
    //     });
    // },

    listAuctionBids({ saleId, limit = 10, nextToken = null }) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `query listAuctionBids($saleId: String!, $limit: Int, $nextToken: String) {
            listAuctionBids(saleId: $saleId, limit: $limit, nextToken: $nextToken) {
                nextToken
                data {
                    id
                    bidValue
                    status
                    owner
                    createdAt
                    personalInfo {
                        firstName
                        lastName
                    }
                    name
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                saleId,
                limit,
                nextToken,
            },
        });
    },
};
