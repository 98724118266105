<template>
    <v-footer
        dark
        class="py-0"
    >
        <v-row justify="space-between">
            <v-col
                cols="12"
                md="9"
                :class="['d-flex', 'align-center', $vuetify.breakpoint.smAndDown ? 'justify-center flex-column' : '']"
            >
                <p class="mb-0 mr-2 copyright">
                    <v-icon size="16">
                        mdi-copyright
                    </v-icon>
                    COPYRIGHT {{ nowYear }} ALL RIGHTS RESERVED.
                </p>
                <a
                    href="#"
                    class="link"
                >TERMS & CONDITIONS</a>
                <span
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="mx-2"
                >|</span>
                <a
                    href="#"
                    class="link"
                >PRIVACY POLICY</a>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: 'AppFooter',

    computed: {
        nowYear() {
            const today = new Date();
            return today.getFullYear();
        },
    },
};
</script>

<style scoped lang="scss">
.v-footer {
    background-color: #000 !important;
    margin-top: 77px;

    .copyright {
        font-size: 14px;
    }

    .link {
        text-decoration: underline;
        color: #fff !important;
    }
}
</style>