export const getDefaultState = () => {
    return {
        options: {
            Residential: null,
            Commercial: null,
            Land: null,
            Basic: null,
        },
        savingState: {
            isSaving: false,
            status: 'Saving documents',
            progress: 0,
            total: 0,
        },
        files: {
            documents: [],
            images: {
                mainImage: null,
                firstRaw: [],
                secondRaw: [],
                hidden: [],
            },
        },
        documents: [],
        images: {
            mainImage: null,
            firstRaw: [],
            secondRaw: [],
            hidden: [],
        },
        title: null,
        address: {},
        openHouseDates: null,
        description: null,
        kind: 'Basic',
        data: {
            features: {},
            interior: {},
            exterior: {},
        },
    };
};

export const state = getDefaultState();
