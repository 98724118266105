<template>
    <v-app>
        <v-app-bar
            v-if="showHeader"
            app
            color="black"
            dark
            height="80px"
            class="app-header"
            fixed
        >
            <div class="app-title-and-user-email">
                <h1
                    class="app-title-and-user-email__app-title"
                    @click="$router.push('/')"
                >
                    Management Dashboard
                </h1>
                <div
                    v-if="$store.state.user"
                    class="app-title-and-user-email__user-email d-flex align-center"
                >
                    <v-menu
                        offset-y
                        left
                        :min-width="menuMinWidth"
                        class="personal-menu"
                    >
                        <template
                            v-slot:activator="{ on, attrs, value }"
                        >
                            <v-btn
                                class="app-title-and-user-email__user-email__button-wrapper white--text"
                                v-bind="attrs"
                                text
                                v-on="on"
                                @click="alignMenuToTheRight()"
                            >
                                <div class="personal-menu__button-icon">
                                    <img
                                        :src="require('@/assets/menu-icons/personal-menu-icons/personal-info-white.svg')"
                                        alt=""
                                    >
                                </div>
                                <span>{{ $store.state.user.email }}</span>
                                <div
                                    :class="[
                                        'app-title-and-user-email__down-arrow',
                                        { 'app-title-and-user-email__down-arrow-active': value },
                                    ]"
                                >
                                    <v-icon>
                                        mdi-menu-down
                                    </v-icon>
                                </div>
                            </v-btn>
                        </template>

                        <v-list class="personal-menu__item-group">
                            <template>
                                <v-list-item
                                    v-for="(item, index) in menu"
                                    :key="index"
                                    class="personal-menu__item-group__item"
                                    @click="menuClick(item.method)"
                                >
                                    <v-list-item-title>
                                        <div class="personal-menu__item-group__item__icon">
                                            <img
                                                :src="getPersonalMenuIconByTitle(item.title)"
                                                alt=""
                                            >
                                        </div>
                                        <span class="text-capitalize">{{ item.title }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </div>
                <v-btn
                    v-else
                    text
                    color="mainRed"
                    @click="openAccountsApp"
                >
                    log in
                </v-btn>
            </div>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        </v-app-bar>
        <v-progress-linear
            v-if="!$route.path.includes('login') && $store.state.showProgressBar && showHeader"
            class="progress-bar"
            indeterminate
            color="primary"
        />
        <v-main>
            <router-view />
        </v-main>
        <AppFooter />
        <v-snackbar
            v-model="snackbar.isOpened"
            :timeout="snackbar.timeout"
            bottom
            :color="snackbar.color"
            class="dark"
        >
            {{ snackbar.text }}

            <template slot="action">
                <v-btn
                    v-if="snackbar.undoCallback"
                    icon
                    @click="snackbar.undoCallback()"
                >
                    <v-icon>mdi-undo</v-icon>
                </v-btn>

                <v-btn
                    icon
                    @click="$store.commit('snackbar', {isOpened: false})"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog
            width="500"
            :value="savingState.isSaving"
        >
            <v-card>
                <v-card-title>
                    <v-progress-circular
                        class="my-10 mx-auto"
                        size="48"
                        indeterminate
                    />
                </v-card-title>

                <v-card-text>
                    <div
                        class="d-flex"
                        :class="savingState.total ? 'justify-space-between' : 'justify-start'"
                    >
                        <span>{{ savingState.status }}...</span>
                        <span v-if="savingState.total">{{ savingState.progress }}/{{ savingState.total }}</span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <PersonalInfoModal
            v-if="$store.getters.isPersonalInfoOpened"
        />
        <user-buyer-modal />
    </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import AppFooter from '@/components/App/AppFooter';
import PersonalInfoModal from '@/components/Modals/PersonalInfoModal';
import UserBuyerModal from './components/Modals/UserBuyerModal.vue';

export default {
    name: 'App',

    components: {
        AppFooter,
        PersonalInfoModal,
        UserBuyerModal,
    },

    data() {
        return {
            menu: [
                {
                    title: 'personal info',
                    method: 'showPersonalInfo',
                    icon: 'mdi-account',
                },
                {
                    title: 'log out',
                    method: 'logOut',
                    icon: 'mdi-logout',
                },
            ],
            menuMinWidth: 180,
        };
    },

    computed: {
        savingState() {
            return this.$store.state.Asset.savingState;
        },

        snackbar() {
            return this.$store.state.snackbar;
        },
        showHeader() {
            return !this.$route.matched.some((r) => r.meta.hideHeader);
        },
    },

    created() {
        window.addEventListener('message', this.messageListener);

        if (this.$store.state.isLoggedIn) {
            this.$store.dispatch('fetchUserData');
        }
    },

    mounted() {
        this.initProgressBar();
    },

    beforeDestroy() {
        window.removeEventListener('message', this.messageListener);
    },

    methods: {
        ...mapActions({
            initProgressBar: 'initProgressBar',
        }),

        openAccountsApp() {
            this.$store.dispatch('openAccountsApp', { path: 'login' });
        },

        menuClick(method) {
            this[method]();
        },

        showPersonalInfo() {
            this.$store.dispatch('openPersonalInfo');
        },

        logOut() {
            this.$store.dispatch('logOut');
        },

        alignMenuToTheRight() {
            if (this.$vuetify.breakpoint.smAndUp) {
                const elementStyle = window.getComputedStyle(
                    document.getElementsByClassName('app-title-and-user-email__user-email__button-wrapper')[0],
                );
                setTimeout(() => {
                    const menuEl = document.getElementsByClassName('v-menu__content')[0];
                    menuEl.style.transform = `translateX(${parseFloat(elementStyle.width) - this.menuMinWidth}px)`;
                }, 5);
            }
        },
        getPersonalMenuIconByTitle(title) {
            const icons = {
                'personal info': require('@/assets/menu-icons/personal-menu-icons/personal-info.svg'),
                'log out': require('@/assets/menu-icons/personal-menu-icons/log-out.svg'),
            };
            return icons[title];
        },

        messageListener(event) {
            if (event.origin !== process.env.VUE_APP_ACCOUNTS_URL) {
                return;
            }

            if (event.data?.action === 'email-updated') {
                this.$store.commit('setUserEmail', event.data.data.email);
                return;
            }
            if (event.data === 'closePersonalInfoModal' || event.message === 'closePersonalInfoModal') {
                this.$store.dispatch('closePersonalInfo');
            } else {
                localStorage.clear();

                Object.keys(event.data).forEach((k) => {
                    localStorage.setItem(k, event.data[k]);
                });

                this.$store.commit('setUser', JSON.parse(localStorage.getItem('currentUser')));

                this.$store.dispatch('fetchUserData').then(() => {
                    this.$router.push('/');
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.card-title {
    font-size: 1.2rem;
}
.progress-bar {
    position: fixed !important;
    top: 80px !important;
    z-index: 1;
}

.app-header {
    display: flex;
    .v-toolbar__content {
        width: 100%;
        justify-content: space-between;
        @media #{map-get($display-breakpoints, 'xs-only')} {
            font-size: 1.5rem;
            padding: 4px 0;
        }
    }
    .app-title-and-user-email {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-left: 5px;
        &__app-title {
            // font-size: 2.7vw;
            cursor: pointer;
            @media #{map-get($display-breakpoints, 'xs-only')} {
                font-size: 24px;
                padding: 0 20px;
            }
        }
        &__user-email {
            align-items: center;
            @media #{map-get($display-breakpoints, 'xs-only')} {
                max-width: 330px;
            }
            &__button-wrapper {
                text-transform: none !important;
                max-width: 100%;
                padding: 0 !important;
                span {
                    @media #{map-get($display-breakpoints, 'xs-only')} {
                        max-width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        &__down-arrow {
            transition: 0.2s !important;
            &-active {
                transform: rotate(180deg) !important;
            }
        }
        @media #{map-get($display-breakpoints, 'xs-only')} {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.drawer {
    transition-duration: 0.4s;
    .close-icon {
        position: absolute;
        right: 28px;
        font-size: 36px;
        top: 15px;
        z-index: 1;
    }
    .v-list {
        margin-bottom: 20px;
        margin-top: 50px;
        .v-list-item {
            height: 50px;
            margin: 0 20px;
            min-height: 20px;
            padding-left: 58px;
            text-transform: uppercase;
            cursor: pointer;
            display: flex;
            justify-items: center;
            &--active {
                background-color: #272424;
            }
            &__title {
                display: flex;
                align-items: center;
            }
            .custom-menu-icon-wrapper {
                min-width: 50px;
            }
        }
    }
}
.v-menu__content {
    border-radius: 0 !important;
    @media #{map-get($display-breakpoints, 'xs-only')} {
        position: absolute !important;
        min-width: 100vw !important;
        left: 0 !important;
    }
}

.personal-menu {
    &__button-icon {
        min-width: 50px;
    }
    &__item-group {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25) !important;
        &__item {
            display: flex;
            &__icon {
                min-width: 30px;
            }
        }
        &__item:last-child {
            border-top: 1px solid #eeeded;
            min-height: 52px;
            padding-top: 8px;
        }
    }
}
.v-list-item__title {
    display: flex;
    align-items: center;
}
</style>
