import Vuex from 'vuex';
import Vue from 'vue';
import { Auth } from 'aws-amplify';
import { Asset } from '@/store/Asset';
import userApi from '@/services/apiCalls/user';
import router from '@/router';

Vue.use(Vuex);

const user = JSON.parse(localStorage.getItem('currentUser'));
const userType = JSON.parse(localStorage.getItem('currentUserType'));

export default new Vuex.Store({
    state: {
        user,
        userType,
        isUserBuyerModalShown: false,
        showProgressBar: false,
        isLoggedIn: localStorage.getItem('authenticated') || false,
        snackbar: {
            isOpened: false,
            text: '',
            color: 'success',
            timeout: 5000,
            undoCallback: null,
        },
        personalInfoModal: {
            value: false,
            tab: null,
        },
    },
    getters: {
        isPersonalInfoOpened: (s) => s.personalInfoModal.value,
        isUserType(state) {
            if (state.isLoggedIn) {
                if (state.userType === 'pro') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
    },
    actions: {
        async fetchUserData({ commit, dispatch }) {
            try {
                const { data } = await userApi.getUser();
                commit('setUserType', data.user.type);
                commit('setUserEmail', data.user.email);
            } catch (error) {
                dispatch('handleError', error);
                return Promise.reject(error);
            }
        },

        async refreshToken({ dispatch }) {
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                const currentSession = await Auth.currentSession();
                return new Promise((res) => {
                    cognitoUser.refreshSession(currentSession.refreshToken, () => {
                        console.log('TOKEN REFRESHED');
                        res();
                    });
                });
            } catch (e) {
                dispatch('handleError', 'Unable to refresh Token', { root: true });
            }
        },

        openAccountsApp(ctx, { width = 980, height = 580, path = '' }) {
            const left = window.screen.width / 2 - width / 2;
            const top = window.screen.height / 2 - height / 2;

            window.open(
                `${process.env.VUE_APP_ACCOUNTS_URL}/#/${path}`,
                'Accounts REBAbids',
                `height=${height},
                width=${width},
                top=${top},
                left=${left},
                location=yes,
                resizable=yes,
                scrollbars=yes,
                status=yes`,
            );
        },
        logOut({ commit, dispatch }) {
            Auth.signOut()
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    commit('setUser', null);
                    commit('setUserType', null);

                    localStorage.clear();
                    dispatch('Subscriptions/unsubscribe', 'onPlanAssigned');

                    router.push('/auth/login');
                });
        },
        initProgressBar({ commit }, initDate) {
            if (initDate) {
                localStorage.setItem('updateTime', initDate.toString());
            }

            if (localStorage.getItem('updateTime')) {
                const startDate = initDate || new Date(localStorage.getItem('updateTime'));
                let timeDiff = (new Date().getTime() - startDate.getTime()) / 1000;

                if (timeDiff < 300) {
                    commit('progressBar', true);

                    const interval = setInterval(() => {
                        timeDiff++;

                        if (timeDiff > 300) {
                            commit('progressBar', false);
                            localStorage.removeItem('updateTime');
                            clearInterval(interval);
                        }
                    }, 1000);
                }
            }
        },
        async handleError({ commit, dispatch }, error) {
            console.log(error);
            const errorType = error.errors && error.errors[0] && error.errors[0]?.errorType;
            if (errorType === 'Unauthorized') {
                await dispatch('refreshToken');

                commit('snackbar', {
                    text: 'Token was refreshed. Please, try again',
                    color: '#139de8',
                    isOpened: true,
                });
            } else {
                let text;
                if (typeof error === 'string') {
                    text = error;
                } else {
                    text = error.errors && error.errors[0]?.message ? error.errors[0].message : 'Something went wrong';
                }

                commit('snackbar', {
                    text,
                    color: 'error',
                    isOpened: true,
                });
            }
        },
        closePersonalInfo({ commit }) {
            commit('closePersonalInfoModal');
        },
        openPersonalInfo({ commit }) {
            commit('openPersonalInfoModal');
        },
        openUserBuyerModal({ commit }) {
            commit('openUserBuyerModal');
        },
        closeUserBuyerModal({ commit }) {
            commit('closeUserBuyerModal');
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            state.isLoggedIn = !!user;
        },
        progressBar(state, show) {
            state.showProgressBar = show;
        },
        snackbar(state, { isOpened, text, color, timeout, undoCallback }) {
            state.snackbar = {
                isOpened,
                text,
                color,
                timeout: timeout || 5000,
                undoCallback: undoCallback || null,
            };
        },
        openUserBuyerModal(state) {
            state.isUserBuyerModalShown = true;
        },
        closeUserBuyerModal(state) {
            state.isUserBuyerModalShown = false;
        },
        setUserEmail(state, email) {
            state.user.email = email;
            localStorage.setItem('currentUser', JSON.stringify(state.user));
        },
        setUserType(state, type) {
            state.userType = type;
            localStorage.setItem('currentUserType', JSON.stringify(state.userType));
        },
        closePersonalInfoModal: (s) => (s.personalInfoModal.value = false),
        openPersonalInfoModal: (s) => (s.personalInfoModal.value = true),
    },
    modules: {
        Asset,
    },
});
