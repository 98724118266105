import { API } from '@aws-amplify/api';

export default {
    getUser() {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;
        const query = `query {
            user: getUser {
                type
                email
            }
        }`;

        return API.graphql({
            query,
            variables: {},
        });
    },
};
