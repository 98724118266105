import assetApi from '@/services/apiCalls/asset';
import router from '@/router';
import { Storage } from 'aws-amplify';
import { Utils } from '@/utils';

export const actions = {
    async createAsset({ state, commit, dispatch }, id = null) {
        try {
            if (!state.files.images.mainImage) {
                commit(
                    'snackbar',
                    {
                        text: 'Please, select primary photo',
                        color: 'error',
                        isOpened: true,
                    },
                    { root: true },
                );

                return;
            }

            await dispatch('saveMedia');
            const documents = await dispatch('saveDocuments');

            commit('setSavingState', { status: `${id ? 'Updating' : 'Creating'} asset`, total: 0 });

            const payload = {
                documents,
                address: state.address,
                openHouseDates: state.openHouseDates,
                description: state.description,
                kind: state.kind,
                data: state.data,
                title: state.title,
                images: state.images,
            };

            if (payload.kind === 'Land') {
                delete payload.data.interior;
                delete payload.data.exterior;
                delete payload.title;
            }

            if (payload.kind === 'Basic') {
                delete payload.openHouseDates;
                delete payload.address;
                delete payload.data;
            }

            let asset = {};

            if (id) {
                const assetData = await assetApi.updateAsset(id, payload);
                asset = assetData.data[`edit${state.kind}Asset`];
            } else {
                const assetData = await assetApi.createAsset(payload);
                asset = assetData.data[`create${state.kind}Asset`];
            }

            commit('setSavingState');

            commit(
                'snackbar',
                {
                    text: id ? 'Asset was successfully updated' : 'Asset was saved as draft',
                    color: 'success',
                    isOpened: true,
                },
                { root: true },
            );

            await router.push(`/assets/short-info/${asset.id}/${asset.kind}`);
        } catch (error) {
            commit('setSavingState');
            dispatch('handleError', error, { root: true });
        }
    },

    // eslint-disable-next-line no-unused-vars
    async deleteAsset({ commit, dispatch }, { id, prevStatus }) {
        try {
            await assetApi.switchStatus(id, 'Deleted');
            commit(
                'snackbar',
                {
                    text: 'Asset was deleted',
                    color: 'success',
                    isOpened: true,
                    // timeout: 30000,
                    /*undoCallback: () => {
                    dispatch('restoreAsset', { id, prevStatus });
                }*/
                },
                { root: true },
            );

            await router.push('/assets?tab=Archive');
        } catch (error) {
            dispatch('handleError', error, { root: true });
        }
    },

    async createListingSale({ commit, dispatch }, object) {
        try {
            await assetApi.createListingSale(object);
            commit(
                'snackbar',
                {
                    text: 'Asset was published',
                    color: 'success',
                    isOpened: true,
                },
                { root: true },
            );
        } catch (e) {
            dispatch('handleError', e, { root: true });
            throw new Error(e);
        }
    },

    async createEnglishAuction({ commit, dispatch }, object) {
        try {
            const data = await assetApi.createEnglishAuction(object);
            commit(
                'snackbar',
                {
                    text: 'Asset was published',
                    color: 'success',
                    isOpened: true,
                },
                { root: true },
            );
            return data;
        } catch (e) {
            dispatch('handleError', e, { root: true });
            throw new Error(e);
        }
    },

    async editAuction({ commit, dispatch }, object) {
        try {
            await assetApi.editAuction(object);
            commit(
                'snackbar',
                {
                    text: 'Asset was updated',
                    color: 'success',
                    isOpened: true,
                },
                { root: true },
            );
        } catch (e) {
            dispatch('handleError', e, { root: true });
            throw new Error(e);
        }
    },

    async restoreAsset({ commit, dispatch }, { id, prevStatus }) {
        try {
            await assetApi.switchStatus(id, prevStatus);
            commit(
                'snackbar',
                {
                    text: 'Deleting was canceled',
                    color: 'success',
                    isOpened: true,
                },
                { root: true },
            );

            await router.push('/assets');
        } catch (error) {
            dispatch('handleError', error, { root: true });
        }
    },

    saveDocuments({ state, commit }) {
        commit('setSavingState', { status: 'Saving documents', progress: 0, total: state.files.documents.length });
        const files = state.files.documents.map(async (file) => {
            if (file.key) {
                return {
                    key: file.key,
                    name: file.name,
                };
            }

            const { key } = await Storage.put(Utils.generateFileKey(file.type), file, {
                level: 'public',
                contentType: file.type,
            });

            commit('setSavingState', { progress: state.savingState.progress + 1 });

            return {
                key: `public/${localStorage.getItem('identityId')}/${key}`,
                name: file.name,
            };
        });

        return Promise.all(files);
    },

    async saveMedia({ state, commit, dispatch }) {
        commit('setSavingState', { status: 'Saving main image', isSaving: true, total: 0 });
        const mainImage = state.files.images.mainImage;

        const res = {
            mainImage: null,
            firstRaw: [],
            secondRaw: [],
            hidden: [],
        };

        if (typeof mainImage === 'string') {
            res.mainImage = mainImage;
        } else {
            const { key } = await Storage.put(Utils.generateFileKey(mainImage.type), mainImage, {
                level: 'public',
                contentType: mainImage.type,
            });

            res.mainImage = `public/${localStorage.getItem('identityId')}/${key}`;
        }

        const imagesNumber = state.files.images.firstRaw.length + state.files.images.secondRaw.length;

        commit('setSavingState', { status: 'Saving images', progress: 0, total: imagesNumber });

        res.firstRaw = await dispatch('saveImages', state.files.images.firstRaw);

        res.secondRaw = await dispatch('saveImages', state.files.images.secondRaw);

        commit('setMediaKeys', res);
    },

    async saveImages({ commit, state }, files) {
        const requests = files.map(async (media) => {
            if (media.type === 'Video' || media.uploaded) {
                return {
                    type: media.type,
                    thumbnail: media.thumbnail,
                    url: media.url,
                };
            }

            const { key } = await Storage.put(Utils.generateFileKey(media.file.type), media.file, {
                level: 'public',
                contentType: media.file.type,
            });

            commit('setSavingState', { progress: state.savingState.progress + 1 });

            return {
                type: 'Image',
                url: `public/${localStorage.getItem('identityId')}/${key}`,
                thumbnail: null,
            };
        });

        return Promise.all(requests);
    },

    async fetchOptions({ state, commit, dispatch }) {
        if (state.options[state.kind]) {
            return;
        }

        try {
            const { data } = await assetApi.getAssetRecommended(state.kind);
            commit('setOptions', data.getAssetRecommended);
        } catch (error) {
            dispatch('handleError', error, { root: true });
        }
    },
};
