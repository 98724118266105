import Vue from 'vue';
import App from './App.vue';
import AppLoader from '@/components/App/AppLoader';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/style/style.scss';

import { awsConfig } from './aws_config';

import Amplify, { Storage } from 'aws-amplify';
import currencyFilter from '@/filters/currencyFilter';
import uppercaseFilter from '@/filters/uppercaseFilter';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

Amplify.configure(awsConfig);

Vue.config.productionTip = false;

Storage.configure({ level: 'private' });

Vue.component('app-loader', AppLoader);

Vue.filter('uppercase', uppercaseFilter);
Vue.filter('currency', currencyFilter);

Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', 'admin.auction-module.quintagroup.com', /^\//],
        }),
    ],
    // tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
});

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
